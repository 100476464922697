import React from "react";
import { useForm } from "react-hook-form";
import { navigate } from 'gatsby';
import { Field, Box, Checkbox, Label, Message, Text, Textarea, Input } from 'theme-ui';
import ActionButton from './ActionButton';
import {endpoint} from "../lib/endpoint";

export default () => {
  const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitting } } = useForm();
  const onSubmit = async (data: any) => {
    console.log("onSubmit, data", data);
    const formData = new FormData();
    const fields = ["title", "description", "date", "start_time", "end_time", "is_public"];
    for (const field of fields) {
      formData.append(field, data[field]);
    }
    if (data.image.length > 0) {
      formData.append("image", data.image[0]);
    }

    console.log("onSubmit, formData", formData);
    try {
      const result = await global.fetch(`${endpoint}/owned-events`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: formData
      });
      if (result.status > 201) {
        global.alert(`Oh no, this failed: ${result.statusText} - Please try again, or refresh the page.`);
      } else {
        const responseBody = JSON.parse(await result.text());
        console.log("responseBody", responseBody);
        navigate(`/owned-events/view/${responseBody.event.id}`);
        alert('Your event was created.');
      }
    } catch (e) {
      console.error('ERR', e);
      global.alert(`Oh no, an error occurred: ${(e as Error).message || ''} - Please try again, or refresh.`);
    }

  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label="Title" {...register("title")} placeholder="The title of your event" mb={3} />
        <Label htmlFor="description">Description</Label>
        <Textarea rows={10} {...register("description")} placeholder="Describe your event, include how to communicate with you, what the cost is, etc. See other events as examples." />
        <Label mt={3} htmlFor="image">Image (optional)</Label>
        <Input type="file" {...register("image")} accept="image/png, image/jpeg" />
        <Label mt={3} htmlFor="date">Which day will the event be?</Label>
        <Field label="Date" {...register("date")} type="date" placeholder="Which day this event will be." mb={3} />
        <Label mt={3} htmlFor="start_time">What time will it start?</Label>
        <Field label="Start Time" {...register("start_time")} type="time" placeholder="What time this event will be." mb={3} />
        <Label mt={3} htmlFor="end_time">And what time will it end?</Label>
        <Field label="Start Time" {...register("end_time")} type="time" mb={3} />
        <Label mt={3} htmlFor="is_public">If the event is public, it will be listed for anyone to find.</Label>
        <Box>
          <Label mb={3}>
            <Checkbox {...register("is_public")} />
            Public event?
          </Label>
        </Box>
        <ActionButton isLoading={false} isSubmitting={isSubmitting} isValid={isValid}>
          {isSubmitting ? 'Updating...' : 'Submit'}
        </ActionButton>
      </form>
    </>
  );
}
