import React from "react";
import { Heading } from "theme-ui";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";
import CreateOwnedEventForm from "../../components/CreateOwnedEventForm";
import MyEventsBreadcrumbs from "../../components/MyEventsBreadcrumbs";

const title = "Create My Own Event";

export default () => {
  return (
    <Layout>
      <Helmet title={title} />
      <MyEventsBreadcrumbs />
      <Heading>{title}</Heading>
      <CreateOwnedEventForm />
    </Layout>
  )
}

